import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const NavigationContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: auto;
  cursor: pointer;
`;

export default NavigationContainer;
