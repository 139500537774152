import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import StyledSecurity from './styles';

import IconCloud from '../../../components/Icons/IconSecurity/IconCloud';
import IconSecurityEye from '../../../components/Icons/IconSecurity/IconEyeSecurity';
import IconList from '../../../components/Icons/IconSecurity/IconList';
import IconLock from '../../../components/Icons/IconSecurity/IconLock';
import IconSetting from '../../../components/Icons/IconSecurity/IconSetting';
import IconShield from '../../../components/Icons/IconSecurity/IconShield';
import IconWall from '../../../components/Icons/IconSecurity/IconWall';

const features1 = [
  { icon: <IconSecurityEye />, text: '7x24 전담 모니터링' },
  { icon: <IconCloud />, text: '다중 포인트 클라우드 데이터' },
  { icon: <IconWall />, text: '의심스러운 위험 경고' },
  { icon: <IconLock />, text: '최고의 안보 방화벽' },
  ,
];

const features2 = [
  { icon: <IconList />, text: 'SSL 암호화 인증서' },
  { icon: <IconSetting />, text: '데이터 암호화 백업' },
  { icon: <IconShield />, text: 'DDOS 공격 방지' },
];

const Security = () => {
  return (
    <StyledSecurity>
      <Box
        sx={{
          backgroundColor: '#000',
          color: '#fff',
          textAlign: 'center',
          py: 4,
          px: 40,
        }}
      >
        <Box sx={{ px: 15 }}>
          {/* Title */}
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            최대 등급의 보안 유지
          </Typography>

          {/* Description */}
          <Typography
            variant="body1"
            sx={{
              // maxWidth: 800,
              margin: '0 auto',
              mb: 4,
              lineHeight: 1.8,
            }}
          >
            정보 보안은 이 사업에서 가장 중요한 구성 요소입니다. 해커와 기타 위협은 단 몇 초 만에 사업을 파괴할수 도
            있습니다. JADE SOFT는 시스템 아키텍처 설계, 시스템 구축 및 지속적인 업그레이드, 운영 및 일상적인 사용 및
            지원까지 다양한 방법의 보안을 유지합니다. 우리는 위험 관리 팀, 정보 보안 전문가 및 실시간 모니터링 팀을 통해
            고객의 내부 및 외부 위협을 제거하기 위해 24시간 연중무휴로 준비하고 있습니다.
          </Typography>
        </Box>

        {/* Features Grid */}
        <Grid container spacing={3} justifyContent="center">
          {features1.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {/* Icon */}
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '50%',
                    p: 0.2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {feature.icon}
                </Box>
                {/* Text */}
                <Typography variant="body1" fontWeight="bold">
                  {feature.text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ px: 15 }}>
          <Grid container spacing={0} justifyContent="center" mt={8}>
            {features2.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {/* Icon */}
                  <Box
                    sx={{
                      backgroundColor: '#fff',
                      color: '#000',
                      borderRadius: '50%',
                      p: 0.2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {feature.icon}
                  </Box>
                  {/* Text */}
                  <Typography variant="body1" fontWeight="bold">
                    {feature.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </StyledSecurity>
  );
};

export default Security;
