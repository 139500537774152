import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledPortfolio = styled(Box)(({ theme }) => ({
  padding: '6% 17.7% 15% 17.7%',
  backgroundColor: '#ffffff',
  '& .slick-slide': {
    width: '500px !important',
    margin: '0 18px',
  },
  '& .MuiPaper-root': {
    borderRadius: 0,
    marginBottom: '50px',
  },
  '& .slick-dots': {
    bottom: '-20 !important',
    marginTop: '70px !important',
    'li button:before ': {
      fontSize: '12px'
    }
  },
  // '& .flickity-page-dots': {
  //   display: 'none',
  // },

  // '& .flickity-enabled': {
  //   outline: 'none',
  // },

  // '& .flickity-button': {
  //   display: 'none',
  // },
  [theme.breakpoints.down('md')]: {
  },

  [theme.breakpoints.down('md')]: {

  },
}));

export default StyledPortfolio;
