import React, { useState } from 'react';

import { Box, Grid, Typography, useTheme } from '@mui/material';

import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import StyledIntroduction from './styles';

import PhoneImage from '../../../assets/images/home/iPhone-Mockup.png';
import JadeGIF from '../../../assets/video/UX_2-min.gif';

// const introductionText1 =
//   'Even though the employees are working hard <br/>if sales do not increase, <br/> there may be a problem with the "solution." <br/> <span className="bold">Just changing the solution can increase sales.</span>';

// const introductionText2 =
//   "We didn't start unless it was the best.<br/>We challenge the best of the system with 20 years of accumulated know-how.";

// const introductionText3 = 'Let me introduce <span>Jade Soft</span>';

const Introduction = () => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const [changeOpacity, setChangeOpacity] = useState(false);

  const label1 = 'What Is a Turnkey Solution';
  const label2 = `빠르게 움직이는 온라인 게임 산업은 수년간의 급속한 성장 끝에 이제 성숙 산업이<br/> 되었으며, 모든 곳에서 치열한 경쟁이 벌어지고 있습니다.<br/> JADE SOFT는 20년의 오랜 노하우를 바탕으로 운영자가 플랫폼을 통해 비용과<br/> 시간을 절약할 수 있도록 합니다.<br/> 다양한 게임, 빠른 입/출금 솔루션, 사전 정의된 운영 프로세스 및 마케팅 기능까지<br/> JADE SOFT 만의 차별화 된 원스톱 솔루션을 경험 하세요.`;

  return (
    <StyledIntroduction>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '100vh',
          backgroundColor: 'white',
          color: theme.palette.background.secondary,
          px: 40,
          paddingTop: 25,
          paddingBottom: 6,
          [theme.breakpoints.down('md')]: {
            px: 1,
            py: 1,
          },
        }}
      >
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 5, lg: 5 }}>
          {/* Left Content */}
          <Grid item xs={12} md={7} lg={7}>
            {/* <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' }, zIndex: 1 }}> */}
            <img src={JadeGIF} style={{ width: '550px', height: '345px' }} />
            <Typography
              variant="body1"
              sx={{
                mt: 1,
                mb: 2,
                ml: 5,
                lineHeight: '44px',
                wordWrap: 'break-word',
                fontWeight: 800,
                fontSize: '40px',
                color: '#444444',
              }}
            >
              {label1}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                ml: 5,
                lineHeight: '26px',
                // wordWrap: 'break-word',
                fontWeight: 800,
                fontSize: '20px',
                color: '#676767',
              }}
            >
              {parse(label2)}
            </Typography>
          </Grid>
          {/* Right Content */}
          <Grid item xs={12} md={5} lg={5}>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                marginTop: -15,
                marginRight: 5,
              }}
            >
              {/* Replace with actual image */}
              <img
                src={PhoneImage}
                alt="Jade Soft Graphics"
                style={{
                  width: '889px',
                  height: '824px',
                  // maxWidth: '100%',
                  // height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </StyledIntroduction>
  );
};

export default Introduction;
