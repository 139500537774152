import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import JadeSoftTeamImage from '../../../assets/images/home/JadeSoftTeam.png';


const StyledJadeSoftTeam = styled(Box)(({ theme }) => ({
//   minHeight: 'calc(190vh - 82px)',
  position: 'relative',
  overflow: 'visible',
  justifyContent: 'center',
//   backgroundImage: `url(${JadeSoftTeamImage})`,
//   backgroundPosition: 'center',
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'cover',
// background: `url(${JadeSoftTeamImage}) lightgray 50% / contain no-repeat`,
backgroundColor: '#ffffff',
  width: '100%',
  '& .homeBg': {
    position: "relative",
    width: "100%",
    height: "500px",
    backgroundImage: `url(${JadeSoftTeamImage})`, // Replace with your image URL
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    textAlign: "center",
    overflow: "hidden",

    '& .videoElement': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '130%',
      width: '100%',
      objectFit: 'cover',
      zIndex: -1,
    },

    '& .bgImage': {
        width: '100%',
        height: '750px',
        objectFit: 'contain',
        position: 'absolute',
    }
  },

  '& .homeContent': {
    // position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1 /* Ensures content is above the video */,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(0),
    // paddingLeft: '20%',
    padding: '0% 20%',
    //  background: `url(${JadeSoftTeamImage}) lightgray 50% / contain no-repeat`,

    '& .bannerText': {
      background: theme.customGradients.primary,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      boxShadow: theme.palette.boxShadow.secondary,
      font: theme.typography.h3.font,
      fontWeight: 400,
      textAlign: 'center',
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: 1,
      '& .iconBrandLogo': {
        width: '300px',
        height: '100px',
      },
    },
  },

  [theme.breakpoints.down('md')]: {
    minHeight: '300vh',
    margin: theme.spacing(0),

    '& .homeBg': {
        '& .bgImage': {
            background: `url(${JadeSoftTeamImage}) lightgray 50% / contain no-repeat`,
            height: '120%',
            objectFit: 'none',
            position: 'absolute',
        }
    },

    '& .homeContent': {
      marginTop: theme.spacing(0),
      marginBottom:  theme.spacing(0),
      justifyContent: 'center',
    //   padding: '0 10%',

      '& .bannerText': {
        margin: theme.spacing(0, 1),
        font: theme.typography.h6.font,
        fontWeight: `${500} !important`,
      },
    },
  },
}));

export default StyledJadeSoftTeam;
