import React from 'react';

import { useRef } from 'react';

import IconClientArea from '../components/Icons/menu/IconClientArea';
// import IconFunction from '../components/Icons/menu/IconFunction';
import IconProduct from '../components/Icons/menu/IconProduct';
// import IconQandA from '../components/Icons/menu/IconQandA';
// import Function from '../pages/Home/Function';
import IGamingOffer from '../pages/Home/IGamingOffer';
// import IGamingServices from '../pages/Home/IGamingServices';
import Introduction from '../pages/Home/Introduction';
// import LatestNews from '../pages/Home/LatestNews';
import IGamingSolution from '../pages/Home/IGamingSolution';
import JadeSoftInterface from '../pages/Home/JadeSoftInterface';
import JadeSoftTeam from '../pages/Home/JadeSoftTeam';
import Portfolio from '../pages/Home/Portfolio';
import PremiumService from '../pages/Home/PremiumService';
import Security from '../pages/Home/Security';
import Subscriptions from '../pages/Home/Subscription';
import WhoIsJadeSoft from '../pages/Home/WhoIsJadeSoft';

export default function useSection() {
  const introRef = useRef(null);
  const serviceRef = useRef(null);
  const functionRef = useRef(null);
  const qaRef = useRef(null);
  const inquiryRef = useRef(null);

  const sections = [
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/introductions',
      Icon: IconProduct,
      isMenu: true,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <Introduction />,
    },
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/jadesoft',
      Icon: IconProduct,
      isMenu: false,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <WhoIsJadeSoft />,
    },
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/igamingoffer',
      Icon: IconProduct,
      isMenu: false,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <IGamingOffer />,
    },
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/igamingsolution',
      Icon: IconProduct,
      isMenu: false,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <IGamingSolution />,
    },
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/jadesof-tteam',
      Icon: IconProduct,
      isMenu: false,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <JadeSoftTeam />,
    },
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/security',
      Icon: IconProduct,
      isMenu: false,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <Security />,
    },
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/interface',
      Icon: IconProduct,
      isMenu: false,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <JadeSoftInterface />,
    },
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/interface',
      Icon: IconProduct,
      isMenu: false,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <PremiumService />,
    },
    {
      id: 'introduction-page',
      label: 'introduction',
      path: '/portfolio',
      Icon: IconProduct,
      isMenu: false,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: introRef,
      children: <Portfolio />,
    },
    // {
    //   id: 'services-page',
    //   label: 'services',
    //   path: '/services',
    //   Icon: IconService,
    //   isMenu: true,
    //   animation: {
    //     initial: { scale: 1 },
    //     hover: { transition: { duration: 1, type: 'spring' }, scale: 1.1 },
    //   },
    //   ref: serviceRef,
    //   children: <IGamingServices />,
    // },
    // {
    //   id: 'function-page',
    //   label: 'function',
    //   path: '/function',
    //   Icon: IconFunction,
    //   isMenu: true,
    //   animation: {
    //     initial: { scale: 1 },
    //     hover: { transition: { duration: 1, type: 'spring' }, scale: 1.1 },
    //   },
    //   ref: functionRef,
    //   children: <Function />,
    // },
    // {
    //   id: 'q&a-page',
    //   label: 'q&a',
    //   path: '/q&a',
    //   Icon: IconQandA,
    //   isMenu: true,
    //   animation: {
    //     initial: { scale: 1 },
    //     hover: { transition: { duration: 1, type: 'spring' }, scale: 1.1 },
    //   },
    //   ref: qaRef,
    //   children: <LatestNews />,
    // },
    {
      id: 'inquiry-page',
      label: 'inquiry',
      path: '/inquiry',
      Icon: IconClientArea,
      isMenu: true,
      animation: {
        initial: { rotate: '0deg', scale: 1 },
        hover: { rotate: '180deg', transition: { duration: 1, type: 'spring' }, scale: 1.1 },
      },
      ref: inquiryRef,
      children: <Subscriptions />,
    },
  ];

  return { sections };
}
