import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import parse from 'html-react-parser';

import BgVideo from '../../../assets/video/nations.mp4';
import StyledWhoIsJadeSoft from './styles';

import AllFlag from '../../../assets/images/home/AllFlag.png';

// import { S3 } from '../../../config/api';

// const bannerText =
// 'Reinventing the platform and developing one from scratch — the Jade Soft team is ready to take on your challenges and create an outstanding tailored iGaming solution';

const WhoIsJadeSoft = () => {
    const theme = useTheme();
    const label1 = 'Who is JADE SOFT?';
    const label2 = `JADE SOFT는 아시아에서 가장 전문적인 iGaming 화이트 라벨 플랫폼 시스템 공급업체입니다.<br/> 게임 산업의 최고 IT 전문가 그룹으로 구성된 JADE SOFT는 가장 기능이 풍부하고 유연하며 강력한 온라인 카지노 플랫폼 솔루션을 개발하는 데<br/> 전념하고 있습니다. 우리의 서비스는 아르헨티나, 브라질, 캄보디아, 중국, 인도, 인도네시아, 일본, 한국, 말레이시아, 멕시코, 나이지리아,<br/> 미얀마, 파키스탄, 태국, 베트남을 포함한 시장이 포함되지만 이에 국한되지 않습니다.<br/> 제이드 소프트는 20년간의 업계 경험 , 지속적인 고객 중심 혁신, 은행 수준의 데이터 보안 사고방식과 다양한 솔루션은<br/> 고객이 사업 성장과 안정성만 집중할 수 있도록 지원하고 있습니다.`;

    return (
        <StyledWhoIsJadeSoft>
            <Box sx={{ height: '20px' }}></Box>
            <Box className="homeBg">
                <video width="100%" autoPlay loop muted playsInline className="videoElement">
                    <source src={BgVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Box>
            <Box className="homeContent">
                {/* <Box className="iconBrandLogo" sx={{ height: '170px' }}>
            <IconBrandLogo />
          </Box> */}
                <Box sx={{ textAlign: { xs: 'center', md: 'center' }, zIndex: 1 }}>
                    <Typography variant="h2" component="h2" sx={{ fontSize: '48px', fontWeight: '800', mb: 8 }}>
                        {label1}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            mb: 10,
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{ lineHeight: 1.8, wordWrap: 'break-word', fontWeight: 700, fontSize: '20px', lineHeight: '26px' }}
                        >
                            {parse(label2)}
                        </Typography>
                    </Box>
                    <Box
                        mb={40}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src={AllFlag}
                            style={{
                                width: '961.03px',
                                height: '220px',
                                maxWidth: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                </Box>

                {/* <Container maxWidth="lg">
          <motion.div
            className="card"
            initial={{
              opacity: 0,
              y: 290,
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 2, // Animation duration
              },
            }}
            // viewport={{ once: true }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box className="bannerText" mt={2} mx={8}>
                {bannerText}
              </Box>
              <Box mt={5}>
                <Button hasEndIcon IconEnd={ArrowForwardIosIcon} text="Get Started" color={COLOR.DEFAULT} />
              </Box>
            </Box>
          </motion.div>
        </Container> */}
            </Box>
        </StyledWhoIsJadeSoft>
    );
};

export default WhoIsJadeSoft;
