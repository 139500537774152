import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import StyledFooter from './style';

import IconFaceBook from '../../components/Icons/IconSocialMedia/IconFaceBook';
import IconGmail from '../../components/Icons/IconSocialMedia/IconGmail';
import IconGoogle from '../../components/Icons/IconSocialMedia/IconGoogle';
import IconInstagram from '../../components/Icons/IconSocialMedia/IconInstagram';
import IconTelegram from '../../components/Icons/IconSocialMedia/IconTelegram';
import IconViber from '../../components/Icons/IconSocialMedia/IconViber';
import IconYoutube from '../../components/Icons/IconSocialMedia/IconYoutube';

const Footer = () => {
  const theme = useTheme();
  const socialMediaIcons = [
    <IconFaceBook />,
    <IconYoutube />,
    <IconGoogle />,
    <IconInstagram />,
    <IconTelegram />,
    <IconViber />,
    <IconGmail />,
  ];
  {
    /* Hid temporarily */
  }
  // const theme = useTheme();
  // const [hideCookie, setHideCookie] = React.useState(false);
  // const handleClose = () => {
  // setHideCookie(true);
  // };
  return (
    <StyledFooter>
      {/* <Box className="footerLogoBox">
        <video width="100%" autoPlay loop muted playsInline className="videoElement">
          <source src={`${S3}/footerLogo.mp4`} type="video/mp4" />
        </video>
      </Box> */}
      {/* <Box className="copyRightBox">
        <div className="separator">Copyright @2024 JADESOFT. All Rights Reserved.</div>
      </Box> */}
      <Box
        className="copyRightBox"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          padding: '30px 0',
        }}
      >
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 5, lg: 5 }}>
          <Grid item xs={12} md={6} lg={6} display={'flex'} justifyContent={'left'}>
            <Typography>© 2025 JADE SOFT. All Right Reserved</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6} display={'flex'} justifyContent={'right'}>
            <Box display={'flex'} justifyContent={'center'}>
              {socialMediaIcons.map((item, index) => (
                <Box
                  sx={{
                    padding: '0 10px',
                  }}
                >
                  {item}
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: '#FFFFFF',
          paddingLeft: 30,
          paddingRight: 30,
          // paddingTop: 5,
          paddingBottom: 20,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            // minHeight: '100vh',
            backgroundColor: '#F0EEEF',
            color: theme.palette.background.secondary,
            // paddingLeft: 30,
            // paddingRight: 20,
            paddingTop: 5,
            // paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
            [theme.breakpoints.down('md')]: {
              px: 1,
              py: 1,
            },
          }}
        >
          <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 5, lg: 5 }}>
            <Grid item xs={12} md={6} lg={6}>
              <Box
                justifyContent={'center'}
                sx={{
                  padding: '0 60px 0 60px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    mb: 2,
                    lineHeight: '44px',
                    wordWrap: 'break-word',
                    fontWeight: 700,
                    fontSize: '47.81px',
                    color: '#000000',
                  }}
                >
                  Book a Demo
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: 1.5,
                    // wordWrap: 'break-word',
                    fontWeight: 600,
                    fontSize: '17.46px',
                    color: '#6C6964',
                  }}
                >
                  Do you want to see the platform in action? Please, leave us your contact details and we promise to get
                  back to you within two business days.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: 3,
                    lineHeight: 1.5,
                    // wordWrap: 'break-word',
                    fontWeight: 600,
                    fontSize: '17.46px',
                    color: '#6C6964',
                  }}
                >
                  Visiting Location and Headquarters:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: 1.5,
                    // wordWrap: 'break-word',
                    fontWeight: 600,
                    fontSize: '17.46px',
                    color: '#DC0B35',
                  }}
                >
                  Miestentie 9, Espoo, FI-02150. Finland
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid display={'flex'}>
                <Box
                  sx={{
                    flex: 1,
                    display: 'inline',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '16px',
                      fontWeight: 700,
                      marginBottom: '15px',
                    }}
                  >
                    First name
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    sx={{
                      border: '1px solid #D7D7D7',
                      borderRadius: '4px',
                      borderColor: '#D7D7D7',
                      marginBottom: '25px',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: 'inline',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '16px',
                      fontWeight: 700,
                      marginBottom: '15px',
                    }}
                  >
                    Last name
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    sx={{
                      border: '1px solid #D7D7D7',
                      borderRadius: '4px',
                      borderColor: '#D7D7D7',
                      marginBottom: '25px',
                    }}
                  />
                </Box>
              </Grid>
              <Grid>
                <Box
                  sx={{
                    flex: 1,
                    display: 'inline',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '16px',
                      fontWeight: 700,
                      marginBottom: '15px',
                    }}
                  >
                    Email*
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    sx={{
                      border: '1px solid #D7D7D7',
                      borderRadius: '4px',
                      borderColor: '#D7D7D7',
                      marginBottom: '15px',
                    }}
                  />
                </Box>
              </Grid>
              <Grid>
                <Box
                  sx={{
                    flex: 1,
                    display: 'inline',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '16px',
                      fontWeight: 700,
                      marginBottom: '15px',
                    }}
                  >
                    Message
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    sx={{
                      border: '1px solid #D7D7D7',
                      borderRadius: '4px',
                      borderColor: '#D7D7D7',
                      marginBottom: '25px',
                    }}
                  />
                </Box>
              </Grid>
              <Grid>
                <Box
                  sx={{
                    flex: 1,
                    display: 'inline',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '16px',
                      fontWeight: 700,
                      marginBottom: '15px',
                    }}
                  >
                    Finnplay is committed to protecting and respecting your privacy, and we’ll only use your personal
                    information to administer your account and to provide the products and services you requested from
                    us. From time to time, we would like to contact you about our products and services, as well as
                    other content that may be of interest to you. If you consent to us contacting you for this purpose,
                    please tick below to say how you would like us to contact you:
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="I agree to receive other communications from JADE SOFT"
                    sx={{
                      color: '#6C6964',
                      fontFamily: 'Helvetica',
                      fontSize: '15.88px',
                      fontWeight: 400,
                    }}
                  />
                  <Box>
                    <Typography
                      sx={{
                        marginTop: 2,
                        color: '#DC0B35',
                        fontSize: '12.8px',
                        fontWeight: 700,
                      }}
                    >
                      Finnplay's Privacy Policy
                    </Typography>
                    <Typography
                      sx={{
                        marginTop: 3,
                        color: '#6C6964',
                        fontSize: '12.7px',
                        fontWeight: 400,
                      }}
                    >
                      By clicking submit below, you consent to allow Finnplay to store and process the personal
                      information submitted above to provide you the content requested.
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        color: '#FFFFFF',
                        backgroundColor: '#CA2E3C',
                        // borderColor: '#00AA2F',
                        borderRadius: '4px',
                        width: '108.03px',
                        height: '65.03px',
                        marginRight: '30px',
                        marginTop: 8,
                        marginBottom: 1,
                        fontSize: '20px',
                        fontWeight: 400,
                        textTransform: 'capitalize',
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box> */}
    </StyledFooter>
  );
};

export default Footer;
