import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFooter = styled(Box)(({ theme }) => ({
  height: '100%',
  background: '#000',

  '& .footerLogoBox': {
    textAlign: 'center',
    backgroundColor: '#000',
    overflow: 'hidden',
    '& .footerLogo': {
      marginTop: '60px',
      marginBottom: '25px',
      maxWidth: '300px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '250px',
      },
    },
    '& .videoElement': {
      height: '30%',
      width: '30%',
      objectFit: 'cover',
      zIndex: -1,
    },
  },
  '& .copyRightBox': {
    paddingLeft: '250px',
    paddingRight: '250px',
    color: '#6A6A6A80',
    fontSize: '14px',
    // marginBottom: '100px',
    '& .separator': {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      '&::before': {
        content: '""',
        flex: '1',
        borderBottom: '0.6px solid #FFFFFF33',
      },
      '&::after': {
        content: '""',
        flex: '1',
        borderBottom: '0.6px solid #FFFFFF33',
      },
      '&:not(:empty)::before': {
        marginRight: '20px',
      },
      '&:not(:empty)::after': {
        marginLeft: '20px',
      },
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
      fontSize: '12px',
      marginBottom: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  '& .cookiesBox': {
    display: 'flex',
    height: '100%',
    textAlign: 'center',
    justifyContent: 'left',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    paddingLeft: '50px',
    '& .closeButton': {
      position: 'absolute',
      right: '15px',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      fontSize: '11px',
      width: '300px',
      paddingLeft: 0,
    },
  },

  [theme.breakpoints.down('md')]: {
    // margin: theme.spacing(0, 2),
    marginBottom: '80px',
  },
}));

export default StyledFooter;
