import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import parse from 'html-react-parser';
import React from 'react';
import Slider from 'react-slick';
import Kansino from '../../../assets/images/home/Kansino.png';
import Spaceman from '../../../assets/images/home/Spaceman.png';
import TheKing from '../../../assets/images/home/TheKing.png';
import StyledPortfolio from './styles';
// Import css files
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import IconPortfolio1 from '../../../components/Icons/IconPortfolio/IconPortfolio1';
import IconPortfolio2 from '../../../components/Icons/IconPortfolio/IconPortfolio2';
import IconPortfolio3 from '../../../components/Icons/IconPortfolio/IconPortfolio3';

const PortfolioCarousel = () => {
  const portfolioItems = [
    {
      title: '',
      description:
        '게임을 도입함으로서 기존 메가카지노 솔루션을 포함하여 다양한 종류의 게임을 즐기 실 수 있는 대한민국에서 유일한 장점을 가지고 있습니다.',
      image: Kansino,
      icon: <IconPortfolio1 />,
    },
    {
      title: '더 킹 플러스 \n THE KING PLUS',
      description:
        '2010년대 부터 운영되어온 국내 최대의 사용자들이 이용하고 있는 메머드급 카지노 사이트로 소액부터 고액까지 다양한 겜블러들이 이용하고 있는 메이저 온라인 카지노 사이트 입니다.',
      image: TheKing,
      icon: <IconPortfolio2 />,
    },
    {
      title: '스페이스맨 카지노 \n SPACE MAN CASINO',
      description:
        '스페이스맨카지노 는 우리카지노 계열 메이저 카지노 사이트로 독특한 매력과 강점을 갖추고 있습니다. 다양한 게임과 풍부한 혜택을 제공하여 사용자들의 만족도를 최고로 끌어올리며, 시간이 지남에 따라 지속적인 향상을 이루어내고 있습니다.',
      image: Spaceman,
      icon: <IconPortfolio3 />,
    },
    {
      title: '',
      description:
        '게임을 도입함으로서 기존 메가카지노 솔루션을 포함하여 다양한 종류의 게임을 즐기 실 수 있는 대한민국에서 유일한 장점을 가지고 있습니다.',
      image: Kansino,
      icon: <IconPortfolio1 />,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <StyledPortfolio>
      <Box sx={{ maxWidth: '1264px', margin: '0 auto', padding: '20px', position: 'relative' }}>
        <Typography
          variant="h4"
          textAlign="center"
          gutterBottom
          sx={{ color: '#000000', fontSize: '40px', fontWeight: 700, mb: 10 }}
        >
          Portfolio
        </Typography>
        <Slider {...settings} sx={{ display: 'flex', justifyContent: 'center', position: 'absolute' }}>
          {portfolioItems.map((item, index) => (
            <Card
              key={index}
              sx={{
                margin: '0 30px',
                background: 'var(--color-white-solid, #FFF)',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
              }}
            >
              <CardMedia component="img" height="270px" width="500px" image={item.image} alt={item.title} />
              <CardContent>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box sx={{ width: '50%' }}>
                    <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 700, color: '#6C6964' }}>
                      {parse(item.title)}
                    </Typography>
                  </Box>
                  {item.icon}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Slider>
      </Box>
    </StyledPortfolio>
  );
};

export default PortfolioCarousel;
