import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const IconGmail = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 20 21'}
    aria-hidden="true"
    width={width || '20'}
    height={height || '21'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit', // TODO: get the width
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.99 8.17397V17.0356C19.99 17.5266 19.8151 17.947 19.4654 18.2967C19.1157 18.6464 18.6953 18.8213 18.2043 18.8213H1.7757C1.28463 18.8213 0.864246 18.6464 0.514544 18.2967C0.164841 17.947 -0.0100098 17.5266 -0.0100098 17.0356V8.17397C0.317371 8.53855 0.693115 8.86221 1.11722 9.14495C3.81067 10.9753 5.65963 12.2588 6.6641 12.9954C7.0882 13.3079 7.43233 13.5516 7.69646 13.7264C7.9606 13.9013 8.31216 14.0798 8.75115 14.2621C9.19014 14.4444 9.59937 14.5356 9.97883 14.5356H9.98999H10.0012C10.3806 14.5356 10.7898 14.4444 11.2288 14.2621C11.6678 14.0798 12.0194 13.9013 12.2835 13.7264C12.5477 13.5516 12.8918 13.3079 13.3159 12.9954C14.5808 12.0802 16.4334 10.7967 18.8739 9.14495C19.298 8.85477 19.67 8.53111 19.99 8.17397ZM19.99 4.89272C19.99 5.48051 19.8077 6.04227 19.4431 6.57798C19.0785 7.1137 18.6247 7.57129 18.0815 7.95075C15.2839 9.89272 13.5428 11.1018 12.8583 11.578C12.7839 11.6301 12.6258 11.7435 12.384 11.9184C12.1421 12.0932 11.9413 12.2346 11.7813 12.3425C11.6213 12.4504 11.4279 12.5713 11.2009 12.7052C10.974 12.8391 10.7601 12.9396 10.5592 13.0066C10.3583 13.0735 10.1723 13.107 10.0012 13.107H9.98999H9.97883C9.8077 13.107 9.62169 13.0735 9.42079 13.0066C9.2199 12.9396 9.00599 12.8391 8.77905 12.7052C8.55212 12.5713 8.35867 12.4504 8.1987 12.3425C8.03873 12.2346 7.83783 12.0932 7.59602 11.9184C7.3542 11.7435 7.19609 11.6301 7.12169 11.578C6.4446 11.1018 5.4699 10.4229 4.19758 9.54115C2.92526 8.65946 2.16261 8.12932 1.90963 7.95075C1.44832 7.63825 1.01306 7.20857 0.60383 6.66169C0.194603 6.11482 -0.0100098 5.607 -0.0100098 5.13825C-0.0100098 4.5579 0.14438 4.07426 0.45316 3.68736C0.76194 3.30046 1.20279 3.107 1.7757 3.107H18.2043C18.6879 3.107 19.1064 3.28185 19.4599 3.63156C19.8133 3.98126 19.99 4.40165 19.99 4.89272Z"
        fill="#777777"
      />
    </svg>
  </SvgIcon>
);

IconGmail.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconGmail;
