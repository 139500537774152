import React, { useState } from 'react';

import { Box, Grid, Typography, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import StyledJadeSoftInterface from './styles';

import InterfaceGif from '../../../assets/video/interface.gif';
import MobileGif from '../../../assets/video/mobile.gif';

// const introductionText1 =
//   'Even though the employees are working hard <br/>if sales do not increase, <br/> there may be a problem with the "solution." <br/> <span className="bold">Just changing the solution can increase sales.</span>';

// const introductionText2 =
//   "We didn't start unless it was the best.<br/>We challenge the best of the system with 20 years of accumulated know-how.";

// const introductionText3 = 'Let me introduce <span>Jade Soft</span>';

const JadeSoftInterface = () => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const [changeOpacity, setChangeOpacity] = useState(false);

  return (
    <StyledJadeSoftInterface>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '100vh',
          backgroundColor: 'white',
          color: theme.palette.background.secondary,
          paddingLeft: 30,
          paddingRight: 20,
          paddingTop: 25,
          paddingBottom: 0,
          [theme.breakpoints.down('md')]: {
            px: 1,
            py: 1,
          },
        }}
      >
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 5, lg: 5 }}>
          {/* Left Content */}
          <Grid item xs={12} md={7} lg={7}>
            {/* <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' }, zIndex: 1 }}> */}
            <img src={InterfaceGif} style={{ width: '550px', height: '345px' }} />
            <Typography
              variant="body1"
              sx={{
                mt: 1,
                mb: 2,
                ml: 5,
                lineHeight: '44px',
                wordWrap: 'break-word',
                fontWeight: 700,
                fontSize: '34px',
                color: '#444444',
              }}
            >
              JADE SOFT Interface Solution
            </Typography>
            <Typography
              variant="body1"
              sx={{
                ml: 5,
                lineHeight: 1.8,
                // wordWrap: 'break-word',
                fontWeight: 800,
                fontSize: '20px',
                color: '#676767',
              }}
            >
              훌륭한 시스템은 멋진 패키지로 제공되어야 합니다.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                ml: 5,
                lineHeight: 1.8,
                // wordWrap: 'break-word',
                fontWeight: 800,
                fontSize: '20px',
                color: '#676767',
              }}
            >
              JADE SOFT의 현지화된 UX과 고급스러운 디자인은 플레이어들에게 사이트에 더 오래, 더 자주 머물게 만들고 싶게
              만듭니다.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 4,
                ml: 5,
                lineHeight: 1.8,
                // wordWrap: 'break-word',
                fontWeight: 800,
                fontSize: '20px',
                color: '#676767',
              }}
            >
              JADE SOFT는 현지를 가장 잘 알고있는 현지 전문 디자이너를 엄선하여 고용합니다. 시스템과 고객에 최적화된
              디자인을 경험하세요.
            </Typography>
            {/* </Box> */}
          </Grid>
          {/* Right Content */}
          <Grid item xs={12} md={5} lg={5}>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                marginTop: -15,
              }}
            >
              {/* Replace with actual image */}
              <img
                src={MobileGif}
                alt="Jade Soft Graphics"
                style={{
                  width: '889px',
                  height: '824px',
                  // maxWidth: '100%',
                  // height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </StyledJadeSoftInterface>
  );
};

export default JadeSoftInterface;
