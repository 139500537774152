import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const IconTelegram = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 21 21'}
    aria-hidden="true"
    width={width || '21'}
    height={height || '21'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit', // TODO: get the width
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1 14.8371L15.7407 7.10268C15.8076 6.7753 15.7686 6.54092 15.6235 6.39955C15.4784 6.25818 15.2868 6.23214 15.0487 6.32143L5.40585 10.0379C5.19008 10.1198 5.04313 10.2128 4.965 10.317C4.88688 10.4211 4.87758 10.5197 4.9371 10.6127C4.99662 10.7057 5.11567 10.7783 5.29424 10.8304L7.76076 11.6004L13.4862 7.99554C13.6425 7.89137 13.7615 7.86905 13.8433 7.92857C13.8954 7.96577 13.8806 8.02158 13.7987 8.09598L9.16701 12.2812L8.98844 14.8259C9.15957 14.8259 9.32698 14.744 9.49067 14.5804L10.696 13.4196L13.196 15.2612C13.6722 15.529 13.9736 15.3876 14.1 14.8371ZM20.0375 6.36607C20.0375 6.36607 20.1696 6.67485 20.4338 7.29241C20.6979 7.90997 20.83 8.89583 20.83 10.25C20.83 11.6042 20.5658 12.8988 20.0375 14.1339C19.5093 15.369 18.7987 16.433 17.9058 17.3259C17.013 18.2188 15.949 18.9293 14.7139 19.4576C13.4788 19.9859 12.1841 20.25 10.83 20.25C9.47579 20.25 8.18115 19.9859 6.94603 19.4576C5.71091 18.9293 4.64692 18.2188 3.75406 17.3259C2.86121 16.433 2.15064 15.369 1.62237 14.1339C1.09409 12.8988 0.829956 11.6042 0.829956 10.25C0.829956 8.89583 1.09409 7.60119 1.62237 6.36607C2.15064 5.13095 2.86121 4.06696 3.75406 3.17411C4.64692 2.28125 5.71091 1.57068 6.94603 1.04241C8.18115 0.514137 9.47579 0.25 10.83 0.25C12.1841 0.25 13.4788 0.514137 14.7139 1.04241C15.949 1.57068 17.013 2.28125 17.9058 3.17411C18.7987 4.06696 19.5093 5.13095 20.0375 6.36607Z"
        fill="#777777"
      />
    </svg>
  </SvgIcon>
);

IconTelegram.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconTelegram;
