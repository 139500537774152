import React from 'react';

import { Box, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import IconJadesoft from '../../components/Icons/IconJadesoft';
import useToggle from '../../hooks/use-toggle';
// import LanguageSettings from './LanguageSettings';
import CustomAppBar from './styles';

// import { languageItems } from '../../config';
import { TOGGLE_LIST_MENU } from '../../constants/global';

import NavigationMenu from '../NavigationMenu';

const TopNavigation = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const pages = [
    {
      label: 'Home',
      path: '/home',
    },
    {
      label: 'Solutions',
      path: '/home',
    },
    {
      label: 'Game APIs',
      path: '/home',
    },
    {
      label: 'Services',
      path: '/home',
    },
    {
      label: 'Contact',
      path: '/home',
    },
    {
      label: 'About Us',
      path: '/home',
    },
    {
      label: 'ENG',
      path: '/home',
    },
  ];

  const { toggle, toggleClickOpen, toggleClickClose } = useToggle({
    toggleList: TOGGLE_LIST_MENU,
  });

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    toggleClickClose('navLanguageOptions');
  };

  return (
    <CustomAppBar color="primary" position="fixed">
      <Toolbar
        className="toolBar"
        disableGutters
        sx={{
          mr: 2,
          alignItems: 'center',
          display: { xs: 'flex' },
          // justifyContent: 'space-between',
          minHeight: '83px !important',
        }}
      >
        <Box display="flex" sx={{ width: '261px' }} onClick={() => navigate('/')}>
          <IconJadesoft />
        </Box>

        <Box sx={{ flexGrow: 0, marginLeft: 10 }}>
          <NavigationMenu pages={pages} />
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <NavigationMenu pages={pages} />
            <LanguageSettings
              onChangeLanguage={onChangeLanguage}
              options={languageItems}
              toggle={toggle.navLanguageOptions}
              toggleClose={() => toggleClickClose('navLanguageOptions')}
              toggleOpen={(e) => toggleClickOpen(e, 'navLanguageOptions')}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <LanguageSettings
              onChangeLanguage={onChangeLanguage}
              options={languageItems}
              toggle={toggle.navLanguageOptions}
              toggleClose={() => toggleClickClose('navLanguageOptions')}
              toggleOpen={(e) => toggleClickOpen(e, 'navLanguageOptions')}
            />
          </Box> */}
        </Box>
      </Toolbar>
    </CustomAppBar>
  );
};

export default TopNavigation;
