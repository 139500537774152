import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledSecurity = styled(Box)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    textAlign: "center",
    padding: "40px 20px",

  [theme.breakpoints.down('md')]: {

  }
}));

export default StyledSecurity;
