import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Box, IconButton, Menu, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

import MenuIcon from '@mui/icons-material/Menu';
import Typography from '../../components/Typography';
import NavigationContainer from './styles';

import { TEXT_TRANSFORM, VARIANT_TYPOGRAPHY } from '../../enums';

const NavigationMenu = ({ pages }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <NavigationContainer maxWidth="xl">
      {/* Navigation links for larger screens */}
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', lg: 'flex' } }} paddingRight={20}>
        {pages.map((page, index) => (
          <Box padding={'5px 20px'}>
            <Typography
              key={index}
              fontWeight={500}
              label={page.label}
              variant={VARIANT_TYPOGRAPHY.H6}
              onClick={() => navigate(page.path)}
              // style={{ padding: '5px 20px' }}
              textTransform={TEXT_TRANSFORM.CAPITALIZE}
              color={theme.palette.text.secondary}
              sx={{
                // padding: '5px 20px',
                color: index === 0 ? '#9340BF' : '#000000', // Highlight active link
                fontFamily: 'Open Sans',
                fontWeight: '700 !important',
                position: 'relative',
                textTransform: 'none',
                fontSize: '16px',
                borderBottom: index === 0 ? '2px solid #5B3EDE' : '2px solid transparent',
                '&:hover': {
                  borderBottom: '2px solid #5B3EDE',
                  color: '#9340BF',
                },
              }}
            />
          </Box>
        ))}
      </Box>
      {/* Hamburger Menu for smaller screens */}
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuOpen}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <MenuIcon sx={{ color: 'black' }} />
      </IconButton>
      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {pages.map((page, index) => (
          <Typography
            key={index}
            fontWeight={500}
            label={page.label}
            variant={VARIANT_TYPOGRAPHY.H6}
            onClick={() => navigate(page.path)}
            style={{ padding: '5px 20px' }}
            textTransform={TEXT_TRANSFORM.CAPITALIZE}
            color={theme.palette.text.primary}
          />
        ))}
      </Menu>
    </NavigationContainer>
  );
};

NavigationMenu.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
};

export default NavigationMenu;
