import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledIGamingSolution = styled(Box)(({ theme }) => ({
  // minHeight: 'calc(250vh - 82px)',
  position: 'relative',
  overflow: 'visible',
  width: '100%',
  background: theme.palette.background.secondary,
  '& .homeBg': {
    // position: 'absolute',
    height: '100%',
    width: '100%',
    // background: 'red',

    '& .videoElement': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '130%',
      width: '100%',
      objectFit: 'cover',
      zIndex: -1,
    },
  },

  '& .homeContent': {
    // position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1 /* Ensures content is above the video */,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: theme.spacing(20),
    // paddingLeft: '20%',
    padding: '2% 12% 10% 12%',
    // background: 'linear-gradient(to bottom, black, gray, white)',
    // background: 'linear-gradient(180deg, #000000 48.02%, #FFFFFF 79.45%)',
    background: '#ffffff',


    '& .bannerText': {
      background: theme.customGradients.primary,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      boxShadow: theme.palette.boxShadow.secondary,
      font: theme.typography.h3.font,
      fontWeight: 400,
      textAlign: 'center',
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: 1,
      '& .iconBrandLogo': {
        width: '300px',
        height: '100px',
      },
    },
  },

  [theme.breakpoints.down('md')]: {
    minHeight: '365vh',
    margin: theme.spacing(0),
    background: '#fff',

    '& .homeContent': {
      marginTop: theme.spacing(0),
      justifyContent: 'center',
      padding: '10% 5%',
      background: '#ffffff',
      '& .bannerText': {
        margin: theme.spacing(0, 1),
        font: theme.typography.h6.font,
        fontWeight: `${500} !important`,
      },
    },
  },
}));

export default StyledIGamingSolution;
