import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const IconFaceBook = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 12 21'}
    aria-hidden="true"
    width={width || '12'}
    height={height || '21'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit', // TODO: get the width
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7802 0.386719V3.33594H9.02234C8.38432 3.33594 7.95463 3.46615 7.73328 3.72656C7.51192 4 7.40125 4.40365 7.40125 4.9375V7.04688H10.663L10.2333 10.3477H7.40125V18.8242H3.98328V10.3477H1.13171V7.04688H3.98328V4.60547C3.98328 3.22526 4.36739 2.15755 5.13562 1.40234C5.91687 0.634115 6.95203 0.25 8.24109 0.25C9.33484 0.25 10.1812 0.295572 10.7802 0.386719Z"
        fill="#777777"
      />
    </svg>
  </SvgIcon>
);

IconFaceBook.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconFaceBook;
