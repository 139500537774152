import { Box } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

const R = '150px';

// Keyframes for circular motion
const circular = keyframes`
  0% {
    transform: translate(0, -${R});
  }
  25% {
    transform: translate(${R}, 0);
  }
  50% {
    transform: translate(20, ${R});
  }
  75% {
    transform: translate(-${R}, 0);
  }
  100% {
    transform: translate(0, -${R});
  }
`;

const circular2 = keyframes`
  0% {
    transform: translate(0, ${R});
  }
  25% {
    transform: translate(-${R}, 0);
  }
  50% {
    transform: translate(0, -${R});
  }
  75% {
    transform: translate(${R}, 0);
  }
  100% {
    transform: translate(0, ${R});
  }
`;

const StyledIntroduction = styled(Box)(({ theme }) => ({
  // minHeight: '15vh',
  // height: '100%',
  // position: 'relative',
  // overflow: 'visible',
  // background: 'black',
  width: '100%',
  minHeight: '100vh',
  overflow: 'hidden',
  position: 'relative',
  //  '& :after': {
  //   content: `''`,
  // 	width: '100%',
  // 	height: '100vh',
  // 	// background: url('https://i.imgur.com/PsjPzdO.png'),
  // 	backgroundSize: '200px',
  // 	mixBlendMode: 'overlay',
  // 	position: 'absolute',
  // 	top: '0',
  // 	left: '0'
  // },
  background: theme.palette.background.secondary,
  '& .fadein': {
    opacity: 1,
    transition: 'opacity .8s ease-in-out,',
  },
  '& .fadeout': {
    opacity: 0,
    transition: 'opacity .8s ease-in-out',
  },
  '& .blobs': {
    width: '30vh',
    height: '30vh',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // filter: contrast(30)

    '& .blob': {
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      position: 'absolute',
      // backgroundColor: '#FF4D00',
      mixBlendMode: 'multiply',
      filter: 'blur(80px)',
    },
    '& .a': {
      backgroundColor: '#009736', // you can modify this dynamically
      animation: `${circular2} 20s linear infinite`,
    },
    '& .b': {
      backgroundColor: '#57C911', // adjust the blend as needed
      animation: `${circular} 20s linear infinite`,
    },
  },
  '& .iconLogo': {
    width: 'auto',
    height: '100px',
    marginBottom: '10px',
  },
  '& .introductionBg': {
    position: 'absolute',
    height: '100%',
    width: '100%',

    '& .videoElement': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      zIndex: -1,
    },
  },

  '& .introductionContent': {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1 /* Ensures content is above the video */,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& .introductionText': {
      color: '#FFFFFF',
      textAlign: 'center',
      fontStyle: theme.typography.h3.fontStyle,
      fontVariant: theme.typography.h3.fontVariant,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: 500,
      lineHeight: '37px',
      '& .bold': {
        fontWeight: '800',
      },
      '& .style': {
        fontStyle: 'italic',
        fontWeight: '600',
        '& span': {
          position: 'relative',
          display: 'inline-block',
          background:
            'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent',
        },
        '& span::after': {
          content: `''`,
          position: 'absolute',
          left: '0',
          right: '0',
          bottom: '0',
          height: '2px',
          background:
            'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))' /* Gradient colors */,
          borderRadius: '2px',
        },
      },
    },

    '& .sectionTitle': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '30px 0px 5px',
    },
    '& .spanTextGradient': {
      background:
        'linear-gradient(262.31deg, #14FF00 0%, #CCFF00 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
      color: 'transparent',
      WebkitBackgroundClip: 'text',
      fontStyle: theme.typography.title.fontStyle,
      fontVariant: theme.typography.title.fontVariant,
      fontSize: theme.typography.title.fontSize,
      fontWeight: `${700} !important`,
    },
  },

  [theme.breakpoints.down('md')]: {
    minHeight: '70vh',

    '& .blobs': {
      width: '30vh',
      height: '30vh',

      '& .blob': {
        width: '70%',
        height: '70%',
      },
    },

    '& .introductionText': {
      fontStyle: `${theme.typography.h5.fontStyle} !important`,
      fontVariant: `${theme.typography.h5.fontVariant} !important`,
      fontSize: `${theme.typography.h5.fontSize} !important`,
      fontWeight: `${500} !important`,
    },

    '& .spanTextGradient': {
      fontStyle: `${theme.typography.h2.fontStyle} !important`,
      fontVariant: `${theme.typography.h2.fontVariant} !important`,
      fontSize: `${theme.typography.h2.fontSize} !important`,
      fontWeight: `${700} !important`,
    },
    '& .iconLogo': {
      width: '300px',
      height: '50px',
    },
  },
}));

export default StyledIntroduction;
