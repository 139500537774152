import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import parse from 'html-react-parser';

import IGamingOfferImage1 from '../../../assets/images/home/IGamingOfferImage1.png';
import IGamingOfferImage2 from '../../../assets/images/home/IGamingOfferImage2.png';
import IGamingOfferImage3 from '../../../assets/images/home/IGamingOfferImage3.png';
import IGamingOfferImage4 from '../../../assets/images/home/IGamingOfferImage4.png';

import StyledIGamingOffer from './styles';

// import { S3 } from '../../../config/api';

// const bannerText =
// 'Reinventing the platform and developing one from scratch — the Jade Soft team is ready to take on your challenges and create an outstanding tailored iGaming solution';

const IGamingOffer = () => {
  const theme = useTheme();

  const label1 = 'What iGaming  Do We Offer';
  const label2 = `JADE SOFT 솔루션이 제공하는 것은 기술 솔루션 뿐만 아니라 고객의 운영을 지원하는 서비스, 바로 파트너십입니다.<br/> JADE SOFT의 위험 분석 팀은 저희의 빅 데이터를 사용하여 비정상적인 승리에 대해 경고하고 위험 분석에 대한 확장된 지원을 제공합니다.<br/> 전담 계정 관리자가 배정되어 고객이 프로젝트 관리, 구성 관리, 가이드 및 시스템 기능을 극대화하는 방법에 대한 제안을 도울 것입니다.`;
  const iGamingOffers = [
    {
      id: 1,
      title: '화이트 라벨',
      content:
        'JADE SOFT White Label은 최단 시간 내에 자체 카지노를 오픈하는 것을 목표로 하는 효율적인 종합 솔루션입니다. 시장에 출시하는 데 걸리는 시간을 절약하고 위험을 최소화하는 장점을 가지고 있습니다.',
      image: IGamingOfferImage1,
    },
    {
      id: 2,
      title: '턴키 솔루션',
      content:
        '턴키 솔루션은 고객에게 기회를 제공하는 자체 소유의 다기능 iGaming 플랫폼입니다. JADE SOFT는 iGaming 플랫폼에 20년의 전문적 경험을 가지고 있습니다. 모든 규모에서 부터 다양한 경험 수준까지 운영자는 처음부터 구축된 원스톱 상점을 활용하여 완벽한제어를 통해 새로운 비지니스 기회를 창출할 수 있습니다.',
      image: IGamingOfferImage2,
    },
    {
      id: 3,
      title: '셀프 서비스',
      content:
        '셀프 서비스는 JADE SOFT의 효율적인 솔루션으로, 무제한으로 사용자가 구현 할 기회를 제공합니다. 이러한 도움을 통해 고객은 자신의 카지노를 만들 수 있으며, JADE SOFT는 모든 단계에서 고객과 함께 합니다.',
      image: IGamingOfferImage3,
    },
    {
      id: 4,
      title: 'Game APIs',
      content:
        'JADE SOFT 솔루션은 110개 이상의 선도적인 iGaming 공급업체와 8,000개 이상의 모바일 친화적이고 다국어 게임을 수십 개의 게임 내 게임화 기능과 함께 제공합니다. 단일 API 통합을 통해 전체 포트폴리오를 얻을 수 있습니다.',
      image: IGamingOfferImage4,
    },
  ];
  return (
    <StyledIGamingOffer>
      {/* <Box className="homeBg">
        <video width="100%" autoPlay loop muted playsInline className="videoElement">
          <source src={BgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box> */}
      <Box className="homeContent">
        <div className="card">
          {/* <Box className="iconBrandLogo" sx={{ height: '170px' }}>
            <IconBrandLogo />
          </Box> */}
          <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'center' }, zIndex: 1 }}>
            <Typography
              variant="h2"
              component="h2"
              sx={{ fontSize: '48px', fontWeight: '800', mb: 8, lineHeight: '54px' }}
            >
              {label1}
            </Typography>
            <Box sx={{ padding: '0 30px' }}>
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1.8,
                  wordWrap: 'break-word',
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '26px',
                  mb: 10,
                }}
              >
                {parse(label2)}
              </Typography>
            </Box>
            <Box display={'grid'} justifyContent={'center'} sx={{ maxWidth: '100%', maxHeight: '100%' }}>
              {iGamingOffers.map((item, index) => (
                <Box sx={{ maxWidth: '100%', maxHeight: '100%', mt: 5 }} display={'flex'} key={item.id}>
                  <Box
                    sx={{
                      backgroundColor: '#ffffff',
                      textAlign: 'left',
                      paddingLeft: 5,
                      paddingRight: 5,
                      // width: '628px',
                      // height: '321.56px',
                      maxWidth: '628px',
                      maxHeight: '100%',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 8,
                        mb: 3,
                        lineHeight: 1,
                        wordWrap: 'break-word',
                        fontWeight: 800,
                        fontSize: '40px',
                        color: '#000000',
                        [theme.breakpoints.down('md')]: {
                          mt: 1,
                        },
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 4,
                        lineHeight: 1.8,
                        wordWrap: 'break-word',
                        fontWeight: 600,
                        fontSize: '17.46px',
                        color: '#6C6964',
                      }}
                    >
                      {item.content}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 8,
                        lineHeight: 1,
                        wordWrap: 'break-word',
                        fontWeight: 400,
                        fontSize: '17.6px',
                        color: '#DC0B35',
                      }}
                    >
                      Read more
                    </Typography>
                  </Box>
                  <Box>
                    <img
                      src={item.image}
                      style={{
                        width: '532px',
                        height: '321.56px',
                        maxWidth: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </div>

        {/* <Container maxWidth="lg">
          <motion.div
            className="card"
            initial={{
              opacity: 0,
              y: 290,
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 2, // Animation duration
              },
            }}
            // viewport={{ once: true }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box className="bannerText" mt={2} mx={8}>
                {bannerText}
              </Box>
              <Box mt={5}>
                <Button hasEndIcon IconEnd={ArrowForwardIosIcon} text="Get Started" color={COLOR.DEFAULT} />
              </Box>
            </Box>
          </motion.div>
        </Container> */}
      </Box>
    </StyledIGamingOffer>
  );
};

export default IGamingOffer;
