import React from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';
import parse from 'html-react-parser';

import BgVideo from '../../../assets/video/main_bg.mp4';
import StyledBanner from './styles';

// import { S3 } from '../../../config/api';

// const bannerText =
// 'Reinventing the platform and developing one from scratch — the Jade Soft team is ready to take on your challenges and create an outstanding tailored iGaming solution';

const Banner = () => {
  const theme = useTheme();
  const label1 = 'The No.1 iGaming Platform';
  const label2 = 'JADE SOFT';
  const label3 = `JADE SOFT는 업계 20년의 노하우로 이뤄낸 <br/> 최고 수준의 온라인 카지노 플랫폼을 제공합니다. <br />당신의 E-BUSINESS만을 위한 단 한번의 API통합 솔루션으로 <br/> 전세계 모든 겜블을 즐겨보세요.`;

  return (
    <StyledBanner>
      <Box className="homeBg">
        <video width="100%" autoPlay loop muted playsInline className="videoElement">
          <source src={BgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Box className="homeContent">
        <div className="card">
          {/* <Box className="iconBrandLogo" sx={{ height: '170px' }}>
            <IconBrandLogo />
          </Box> */}
          <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' }, zIndex: 1 }}>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                // color: 'rgba(255, 255, 255, 0.7)',
                color: '#000000',
                fontWeight: 900,
                fontSize: '48px',
                // textShadow:
                //   '1px 1px 0 white, -1px 1px 0 white, 1px -1px 0 white, -1px -1px 0 white, 0 1px 0 white, 0 -1px 0 white, 1px 0 0 white, -1px 0 0 white',
                // textShadow: '-1px 0 #ffffff, 0 1px #ffffff, 1px 0 #ffffff, 0 -1px #ffffff',
                WebkitTextStrokeWidth: '0.5px', // Apply text stroke width
                WebkitTextStrokeColor: '#FFF', // Apply text stroke color
                lineHeight: 1,
                fontFamily: 'Pretendard',
              }}
            >
              {label1}
            </Typography>
            <Typography variant="h2" component="h2" sx={{ fontSize: '96px', fontWeight: '900', lineHeight: '116px' }}>
              {label2}
            </Typography>
            <Box
              sx={{
                // width: '400px',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{ mb: 4, wordWrap: 'break-word', fontWeight: 800, fontSize: '20px', lineHeight: '26px' }}
              >
                {parse(label3)}
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#00FF00',
                color: 'black',
                fontSize: '16px',
                fontWeight: '800',
                borderRadius: '5px',
                padding: '10px 40px',
                '&:hover': {
                  backgroundColor: '#00cc00',
                },
              }}
            >
              문의하기
            </Button>
          </Box>
        </div>

        {/* <Container maxWidth="lg">
          <motion.div
            className="card"
            initial={{
              opacity: 0,
              y: 290,
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 2, // Animation duration
              },
            }}
            // viewport={{ once: true }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box className="bannerText" mt={2} mx={8}>
                {bannerText}
              </Box>
              <Box mt={5}>
                <Button hasEndIcon IconEnd={ArrowForwardIosIcon} text="Get Started" color={COLOR.DEFAULT} />
              </Box>
            </Box>
          </motion.div>
        </Container> */}
      </Box>
    </StyledBanner>
  );
};

export default Banner;
