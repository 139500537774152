import React from 'react';

import { Box, Grid, Typography, useTheme } from '@mui/material';

import StyledJadeSoftTeam from './styles';

//icon jadesoft team
import IconAI from '../../../components/Icons/IconJadeSoftTeam/IconAI';
import IconDocument from '../../../components/Icons/IconJadeSoftTeam/IconDocument';
import IconEye from '../../../components/Icons/IconJadeSoftTeam/IconEye';
import IconPeople from '../../../components/Icons/IconJadeSoftTeam/IconPeople';

// import { S3 } from '../../../config/api';

// const bannerText =
// 'Reinventing the platform and developing one from scratch — the Jade Soft team is ready to take on your challenges and create an outstanding tailored iGaming solution';

const JadeSoftTeam = () => {
  const theme = useTheme();

  const iGamingSolutions = [
    {
      id: 1,
      title: '자동화된 프로모션 시스템',
      content:
        '몇 단계의 구성만으로 등록, 일일 로그인, 사이트 대회와 같은 완전 자동화된 프로모션을 설정할 수 있습니다. 이 시스템은 상상을 초월하는 풍부한 프로모션 도구와 제어 기능을 제공하며, 발급, 청구, 고객 알림에서 매출 제어까지 모든 것이 인간의 개입 없이 자동화됩니다.',
      image: <IconPeople />,
    },
    {
      id: 2,
      title: 'AI 위험 관리 모니터링',
      content:
        '20년이상의 숙련된 JADE SOFT 기술팀은 조기 위험 경고 시스템의 연구 개발에 전념해 왔습니다. 빅데이터를 기반으로 JADE SOFT의 지능형 위험 관리 모니터링 시스템을 사용하면 많은 인력과 추적 작업에 필요한 기존 위험 관리 작업을 줄일 수 있습니다.',
      image: <IconEye />,
    },
    {
      id: 3,
      title: '강력한 제휴 관리 시스템',
      content:
        '제휴사와 복잡한 커미션 정책을 관리가 어려우신가요?JADE SOFT의 무제한 레벨 제휴사 시스템은 유연한 커미션 설정 및 자동 계산 도구와 함께 제공됩니다.',
      image: <IconAI />,
    },
    {
      id: 4,
      title: '다양한 분야의 컨설팅',
      content:
        'JADE SOFT 플랫폼은 일상 업무, 심층 마케팅 분석, 제휴사 분석, 운영 효율성, 재무 조정, 상사를 위한 기업 P&L을 포함한 모든 측면을 포괄하는 보고서를 제공합니다',
      image: <IconDocument />,
    },
  ];

  return (
    <StyledJadeSoftTeam>
      <Box className="homeBg">
        {/* <img className="bgImage" src={JadeSoftTeamImage} /> */}
        <Box
          sx={{
            textAlign: { xs: 'center', md: 'center' },
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            zIndex: 1,
            padding: '8% 25%',
          }}
        >
          <Typography variant="h2" component="h2" sx={{ fontSize: '48px', fontWeight: '800' }}>
            JADE SOFT
          </Typography>
          <Typography variant="h2" component="h2" sx={{ fontSize: '32px', fontWeight: '700' }}>
            Empowers Your Team
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{ lineHeight: 1.8, wordWrap: 'break-word', fontWeight: 800, fontSize: '20px', mt: 2 }}
            >
              비즈니스 성장을 극대화하려면 팀이 수동적인 작업에서 벗어나 관리와 효율에 집중해야 합니다. JADE SOFT
              플랫폼은 자동 프로모션 도구, 제휴사 관리 시스템, 위험 분석 도구, 풍부한 경험을 제공합니다. 이를 통해
              진정한 비즈니스의 잠재력을 이끌어내고 실현할 수 있습니다.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="homeContent">
        {/* <Box className="iconBrandLogo" sx={{ height: '170px' }}>
            <IconBrandLogo />
          </Box> */}
        <Box
          mt={15}
          mb={20}
          display={'flex'}
          justifyContent={'center'}
          sx={{ maxWidth: '100%', maxHeight: '100%', color: '#000000' }}
        >
          <Grid container spacing={2}>
            {iGamingSolutions.map((item, index) => (
              <Grid item xs={6} sm={3} md={6} key={index}>
                <Box sx={{ maxWidth: '100%', maxHeight: '100%', mt: 5 }} display={'flex'} key={item.id}>
                  <Box
                    sx={{
                      backgroundColor: '#ffffff',
                      textAlign: 'center',
                      paddingLeft: 5,
                      paddingRight: 5,
                      // width: '628px',
                      // height: '321.56px',
                      //   maxWidth: '628px',
                      maxHeight: '100%',
                    }}
                  >
                    <Box>{item.image}</Box>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 2,
                        mb: 6,
                        lineHeight: 1,
                        wordWrap: 'break-word',
                        fontWeight: 800,
                        fontSize: '21.3px',
                        color: '#676767',
                        [theme.breakpoints.down('md')]: {
                          mt: 1,
                        },
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 4,
                        lineHeight: 1.8,
                        wordWrap: 'break-word',
                        fontWeight: 600,
                        fontSize: '16px',
                        color: '#676767',
                      }}
                    >
                      {item.content}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </StyledJadeSoftTeam>
  );
};

export default JadeSoftTeam;
