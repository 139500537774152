import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography, useTheme } from '@mui/material';
import React from 'react';
import IconTelegram from '../../../components/Icons/IconTelegram';
import StyledSubcription from './styles';

const Subscriptions = () => {
  const theme = useTheme();

  return (
    <StyledSubcription>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          // minHeight: '100vh',
          backgroundColor: '#F0EEEF',
          color: theme.palette.background.secondary,
          paddingLeft: 45,
          paddingRight: 45,
          paddingTop: 8,
          paddingBottom: 20,
          [theme.breakpoints.down('md')]: {
            px: 1,
            py: 1,
          },
        }}
      >
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 5, lg: 5 }}>
          {/* Left Content */}
          <Grid item xs={12} md={6} lg={6}>
            <Box
              justifyContent={'center'}
              sx={{
                padding: '130px 60px 0 60px',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  mt: 1,
                  mb: 2,
                  lineHeight: '44px',
                  wordWrap: 'break-word',
                  fontWeight: 700,
                  fontSize: '47.81px',
                  color: '#000000',
                }}
              >
                문의하기
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1.8,
                  // wordWrap: 'break-word',
                  fontWeight: 600,
                  fontSize: '17.46px',
                  color: '#6C6964',
                }}
              >
                귀하께서 원하시는 iGaming 서비스를 알려주세요.JADE SOFT는 귀하의 니즈를 충족시킬 수 있도록 끊임없는
                열정과 혁신의 성공적 실행으로 새롭고 차별화된서비스를 제공할 것 입니다.
              </Typography>
              <IconTelegram />
            </Box>
          </Grid>
          {/* Right Content */}
          <Grid item xs={12} md={6} lg={6}>
            <Grid>
              <Box
                sx={{
                  flex: 1,
                  display: 'inline',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 3,
                }}
              >
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 700,
                    marginBottom: '15px',
                  }}
                >
                  이름
                </Typography>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  sx={{
                    border: '1px solid #D7D7D7',
                    borderRadius: '4px',
                    borderColor: '#D7D7D7',
                    marginBottom: '25px',
                  }}
                />
              </Box>
            </Grid>
            <Grid>
              <Box
                sx={{
                  flex: 1,
                  display: 'inline',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 700,
                    marginBottom: '15px',
                  }}
                >
                  Email*
                </Typography>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  sx={{
                    border: '1px solid #D7D7D7',
                    borderRadius: '4px',
                    borderColor: '#D7D7D7',
                    marginBottom: '15px',
                  }}
                />
              </Box>
            </Grid>
            <Grid>
              <Box
                sx={{
                  flex: 1,
                  display: 'inline',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 700,
                    marginBottom: '15px',
                  }}
                >
                  문의내용
                </Typography>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  sx={{
                    border: '1px solid #D7D7D7',
                    borderRadius: '4px',
                    borderColor: '#D7D7D7',
                    marginBottom: '25px',
                  }}
                />
              </Box>
            </Grid>
            <Grid>
              <Box
                sx={{
                  flex: 1,
                  display: 'inline',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 700,
                    marginBottom: '15px',
                  }}
                >
                  JADE SOFT는 귀하의 개인 정보를 보호하고 존중하는 데 전념하며, 귀하의 개인 정보는 귀하의 계정을
                  관리하고 귀하가 요청한 제품과 서비스를 제공하는 데에만 사용합니다. 때때로, 당사는 귀하에게 당사의
                  제품과 서비스와 귀하에게 흥미로울 수 있는 다른 콘텐츠에 대해 연락하고자 합니다. 이러한 목적으로 당사가
                  귀하에게 연락하는 데 동의하는 경우, 아래에 체크 표시하여 당사가 귀하에게 연락하기를 원하는 방법을
                  알려주십시오.
                </Typography>
                <FormControlLabel
                  control={<Checkbox />}
                  label="I agree to receive other communications from JADE SOFT"
                  sx={{
                    color: '#6C6964',
                    fontFamily: 'Helvetica',
                    fontSize: '15.88px',
                    fontWeight: 400,
                  }}
                />
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      color: '#000000',
                      backgroundColor: '#00FF00',
                      // borderColor: '#00AA2F',
                      borderRadius: '4px',
                      width: '115.03px',
                      height: '65.03px',
                      marginRight: '30px',
                      marginTop: 4,
                      fontSize: '20px',
                      fontWeight: 600,
                    }}
                  >
                    등록하기
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledSubcription>
  );
};

export default Subscriptions;
