import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledWhoIsJadeSoft = styled(Box)(({ theme }) => ({
    minHeight: 'calc(130vh - 82px)',
    position: 'relative',
    overflow: 'visible',
    justifyContent: 'center',
    // backgroundColor: '#000000',
    width: '100%',
    '& .homeBg': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        // background: 'red',

        '& .videoElement': {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '130%',
            width: '100%',
            objectFit: 'cover',
            zIndex: -1,
        },
    },

    '& .homeContent': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 1 /* Ensures content is above the video */,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(30),
        // paddingLeft: '20%',
        padding: '10% 20%',

        '& .bannerText': {
            background: theme.customGradients.primary,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            boxShadow: theme.palette.boxShadow.secondary,
            font: theme.typography.h3.font,
            fontWeight: 400,
            textAlign: 'center',
        },

        [theme.breakpoints.down('md')]: {
            paddingLeft: 1,
            '& .iconBrandLogo': {
                width: '300px',
                height: '100px',
            },
        },
    },

    [theme.breakpoints.down('md')]: {
        minHeight: '80vh',
        margin: theme.spacing(0),

        '& .homeContent': {
            marginTop: theme.spacing(30),
            marginBottom: theme.spacing(0),
            justifyContent: 'center',
            padding: '0 10%',

            '& .bannerText': {
                margin: theme.spacing(0, 1),
                font: theme.typography.h6.font,
                fontWeight: `${500} !important`,
            },
        },
    },
}));

export default StyledWhoIsJadeSoft;
