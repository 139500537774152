import { SvgIcon } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const IconGoogle = ({ height, width }) => (
  <SvgIcon
    focusable="false"
    viewBox={!isNil(height) && !isNil(width) ? `0 0 ${width} ${height}` : '0 0 18 21'}
    aria-hidden="true"
    width={width || '18'}
    height={height || '21'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: !isNil(width) ? `${width}px` : 'inherit', // TODO: get the width
      height: !isNil(height) ? `${height}px` : 'inherit',
    }}
  >
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.08493 9.02218H17.1717C17.261 9.52069 17.3056 9.99688 17.3056 10.4508C17.3056 12.0653 16.9672 13.5069 16.2905 14.7755C15.6139 16.0441 14.649 17.0356 13.396 17.7499C12.143 18.4641 10.706 18.8213 9.08493 18.8213C7.91745 18.8213 6.80575 18.5962 5.74982 18.1461C4.69389 17.6959 3.78296 17.0877 3.01704 16.3213C2.25111 15.5549 1.64321 14.6435 1.19332 13.5869C0.743437 12.5304 0.518494 11.418 0.518494 10.2499C0.518494 9.08171 0.743437 7.96936 1.19332 6.91281C1.64321 5.85626 2.25111 4.9448 3.01704 4.17843C3.78296 3.41206 4.69389 2.8038 5.74982 2.35366C6.80575 1.90351 7.91745 1.67843 9.08493 1.67843C11.3158 1.67843 13.2306 2.4262 14.8293 3.92174L12.4981 6.16504C11.5835 5.27962 10.4457 4.83692 9.08493 4.83692C8.12567 4.83692 7.23891 5.07873 6.42465 5.56236C5.61039 6.04599 4.96531 6.70261 4.48939 7.53223C4.01348 8.36184 3.77552 9.26772 3.77552 10.2499C3.77552 11.232 4.01348 12.1379 4.48939 12.9675C4.96531 13.7971 5.61039 14.4537 6.42465 14.9374C7.23891 15.421 8.12567 15.6628 9.08493 15.6628C9.73187 15.6628 10.3268 15.5735 10.8696 15.3949C11.4124 15.2164 11.8586 14.9932 12.2081 14.7253C12.5576 14.4574 12.8625 14.1524 13.1228 13.8101C13.383 13.4679 13.5745 13.1442 13.6972 12.8391C13.8199 12.5341 13.9035 12.2439 13.9482 11.9686H9.08493V9.02218Z"
        fill="#777777"
      />
    </svg>
  </SvgIcon>
);

IconGoogle.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IconGoogle;
