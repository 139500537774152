import React from 'react';

import { Box, Button, Divider, Grid, TextField, Typography, useTheme } from '@mui/material';
import parse from 'html-react-parser';

//icon solution
import IconSolution1 from '../../../components/Icons/IconSolution/IconSolution1';
import IconSolution2 from '../../../components/Icons/IconSolution/IconSolution2';
import IconSolution3 from '../../../components/Icons/IconSolution/IconSolution3';
import IconSolution4 from '../../../components/Icons/IconSolution/IconSolution4';
import IconSolution5 from '../../../components/Icons/IconSolution/IconSolution5';
import IconSolution6 from '../../../components/Icons/IconSolution/IconSolution6';
import IconSolution7 from '../../../components/Icons/IconSolution/IconSolution7';
import IconSolution8 from '../../../components/Icons/IconSolution/IconSolution8';

//icon providers
import GGaming from '../../../components/Icons/IconProvider/5GGaming';
import AllBet from '../../../components/Icons/IconProvider/AllBet';
import AmbSlot from '../../../components/Icons/IconProvider/AmbSlot';
import AsiaGaming from '../../../components/Icons/IconProvider/AsiaGaming';
import AvatarUX from '../../../components/Icons/IconProvider/AvatarUX';
import BetGames from '../../../components/Icons/IconProvider/BetGames';
import BeterLive from '../../../components/Icons/IconProvider/BeterLive';
import Casino from '../../../components/Icons/IconProvider/Casino';
import DreamGaming from '../../../components/Icons/IconProvider/DreamGaming';
import GoatBet from '../../../components/Icons/IconProvider/GoatBet';
import HoGaming from '../../../components/Icons/IconProvider/HoGaming';
import ImagineLive from '../../../components/Icons/IconProvider/ImagineLive';
import JadeSports from '../../../components/Icons/IconProvider/JadeSports';
import KAGaming from '../../../components/Icons/IconProvider/KAGaming';
import Microgaming from '../../../components/Icons/IconProvider/Microgaming';
import PragmaticPlay from '../../../components/Icons/IconProvider/PragmaticPlay';
import PrettyGaming from '../../../components/Icons/IconProvider/PrettyGaming';
import RedTiger from '../../../components/Icons/IconProvider/RedTiger';
import SimplePlay from '../../../components/Icons/IconProvider/SimplePlay';
import SportsPlus from '../../../components/Icons/IconProvider/SportsPlus';
import TFGaming from '../../../components/Icons/IconProvider/TFGaming';
import Vivo from '../../../components/Icons/IconProvider/Vivo';
import WorldEntertainment from '../../../components/Icons/IconProvider/WorldEntertainment';
import YeebetGaming from '../../../components/Icons/IconProvider/YeebetGaming';

import StyledIGamingSolution from './styles';

// import { S3 } from '../../../config/api';

// const bannerText =
// 'Reinventing the platform and developing one from scratch — the Jade Soft team is ready to take on your challenges and create an outstanding tailored iGaming solution';

const IGamingSolution = () => {
  const theme = useTheme();
  const label1 = 'Why JADE SOFT iGaming<br/> Solution?';
  const label2 = `JADE SOFT 솔루션은 20년간의 업계 지식과 IT 연구 및 개발을 축적했습니다.<br/> 안정적이고 신뢰할 수 있으며 이미 오랫동안 업계에서 입증되었습니다.<br/> 8가지 주요 장점을 갖춘 JADE SOFT 솔루션은 온라인 게임 운영자를 위한 원스톱 솔루션입니다.`;
  const iGamingSolutions = [
    {
      id: 1,
      title: '업계 20년 경험의 노하우',
      content: '업계 20년의 경험으로  고객에게 안정적인<br/> 비즈니스를 제공합니다',
      image: <IconSolution1 />,
    },
    {
      id: 2,
      title: '풍부한 게임 컬렉션 제공',
      content: '제공되는 게임의 공급 업체 000, 게임종류 000<br/> 이상이며 매달 추가 업데이트 되고 있습니다',
      image: <IconSolution2 />,
    },
    {
      id: 3,
      title: '고객 맞춤형 디자인',
      content: '고객이 원하는 디자인으로 제작되는 당신만의<br/> 독점 브랜드를 만들 수 있습니다',
      image: <IconSolution3 />,
    },
    {
      id: 4,
      title: '독점 컨설팅 서비스',
      content: '전문 컨설팅 팀이 고객의 비즈니스를 위해항상<br/> 준비하고 있습니다.',
      image: <IconSolution4 />,
    },
    {
      id: 5,
      title: '자동화된 프로모션 엔진',
      content:
        '등록, 일일 로그인, 입금 보너스, 마일리지 시스템 등<br/> 다양한 프로모션이 유연한 시스템으로<br/> 완전 자동화되어 있습니다',
      image: <IconSolution5 />,
    },
    {
      id: 6,
      title: '모든 장치 호환',
      content: 'PC, Mac, iOS, Android, iOS/Android<br/> APP이 모두 포함되어 있습니다',
      image: <IconSolution6 />,
    },
    {
      id: 7,
      title: '빅 데이터 AI 분석',
      content: '실시간 모니터링 및 경보 시스템을 통해<br/> 완벽한 안정성을 구현합니다',
      image: <IconSolution7 />,
    },
    {
      id: 8,
      title: '강력한 제휴 시스템',
      content: '제휴 시스템을 사용하면 제휴사에게 자동으로<br/> 비용을 계산하고 지불할 수 있습니다.',
      image: <IconSolution8 />,
    },
  ];

  const providers = [
    {
      id: 1,
      image: <AsiaGaming />,
    },
    {
      id: 2,
      image: <BetGames />,
    },
    {
      id: 3,
      image: <BeterLive />,
    },
    {
      id: 4,
      image: <DreamGaming />,
    },
    {
      id: 5,
      image: <ImagineLive />,
    },
    {
      id: 6,
      image: <Microgaming />,
    },
    {
      id: 7,
      image: <PragmaticPlay />,
    },
    {
      id: 8,
      image: <Vivo />,
    },
    {
      id: 9,
      image: <Casino />,
    },
    {
      id: 10,
      image: <WorldEntertainment />,
    },
    {
      id: 11,
      image: <YeebetGaming />,
    },
    {
      id: 12,
      image: <HoGaming />,
    },
    {
      id: 13,
      image: <PrettyGaming />,
    },
    {
      id: 14,
      image: <GGaming />,
    },
    {
      id: 15,
      image: <AllBet />,
    },
    {
      id: 16,
      image: <AmbSlot />,
    },
    {
      id: 17,
      image: <AvatarUX />,
    },
    {
      id: 18,
      image: <KAGaming />,
    },
    {
      id: 19,
      image: <SimplePlay />,
    },
    {
      id: 20,
      image: <RedTiger />,
    },
    {
      id: 21,
      image: <GoatBet />,
    },
    {
      id: 22,
      image: <SportsPlus />,
    },
    {
      id: 23,
      image: <TFGaming />,
    },
    {
      id: 24,
      image: <JadeSports />,
    },
  ];

  return (
    <StyledIGamingSolution>
      {/* <Box className="homeBg">
        <video width="100%" autoPlay loop muted playsInline className="videoElement">
          <source src={BgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box> */}
      <Box className="homeContent">
        <div className="card">
          {/* <Box className="iconBrandLogo" sx={{ height: '170px' }}>
            <IconBrandLogo />
          </Box> */}
          <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'center' }, zIndex: 1 }}>
            <Typography
              variant="h2"
              component="h2"
              sx={{ fontSize: '48px', fontWeight: '700', mb: 4, color: '#000000', lineHeight: '59.5px' }}
            >
              {parse(label1)}
            </Typography>
            <Box sx={{ padding: '0 30px' }}>
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1.8,
                  wordWrap: 'break-word',
                  fontWeight: 700,
                  fontSize: '20px',
                  color: '#000000',
                  lineHeight: '26px',
                }}
              >
                {parse(label2)}
              </Typography>
            </Box>
            <Box
              mt={5}
              mb={20}
              // px={12}
              display={'flex'}
              justifyContent={'center'}
              sx={{ maxWidth: '100%', maxHeight: '100%', color: '#000000' }}
            >
              <Grid container spacing={2}>
                {iGamingSolutions.map((item, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <Box sx={{ maxWidth: '100%', maxHeight: '100%', mt: 5 }} display={'flex'} key={item.id}>
                      <Box
                        sx={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          // paddingLeft: 0,
                          // paddingRight: 0,
                          // width: '628px',
                          // height: '321.56px',
                          maxWidth: '628px',
                          maxHeight: '100%',
                        }}
                      >
                        <Box px={12}>{item.image}</Box>
                        <Box display={'flex'} justifyContent={'center'} px={8}>
                          <Typography
                            variant="body1"
                            sx={{
                              mt: 2,
                              mb: 5,
                              lineHeight: '26.67px',
                              wordWrap: 'break-word',
                              fontWeight: 800,
                              fontSize: '21.3px',
                              color: '#676767',
                              [theme.breakpoints.down('md')]: {
                                mt: 1,
                              },
                            }}
                          >
                            {item.title}
                          </Typography>
                        </Box>
                        <Box px={1}>
                          <Typography
                            variant="body1"
                            sx={{
                              mb: 4,
                              lineHeight: '20px',
                              // wordWrap: 'break-word',
                              fontWeight: 600,
                              fontSize: '16px',
                              color: '#676767',
                            }}
                          >
                            {parse(item.content)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider
              sx={{
                backgroundColor: '#21E418',
                color: '#21E418',
                width: '133px',
                height: '8px',
                justifySelf: 'center',
                marginBottom: 15,
              }}
            ></Divider>
            <Box sx={{ padding: '0 20%' }}>
              <Typography
                variant="h2"
                component="h2"
                sx={{ fontSize: '48px', fontWeight: '800', mb: 4, color: '#000000' }}
              >
                110+ Aggregated Game Providers & 8000+ Games
              </Typography>
            </Box>
            <Box sx={{ padding: '0 20%' }}>
              <Typography
                variant="body1"
                sx={{ lineHeight: 1.8, wordWrap: 'break-word', fontWeight: 800, fontSize: '20px', color: '#000000' }}
              >
                JADE SOFT는 전세계의 다양한 공급사의 소프트웨어 및 서비스를 제공받고 있습니다.
              </Typography>
              <Typography
                variant="body1"
                sx={{ lineHeight: 1.8, wordWrap: 'break-word', fontWeight: 800, fontSize: '20px', color: '#000000' }}
              >
                모든 게임을 신중하게 선택, 테스트 및 검토하여 각 게임의 품질이 기대에 부응할 수 있도록 했으며새로운
                공급사와 게임들은 지속적으로 추가되고 있습니다.
              </Typography>
            </Box>
            <Box mt={5} display={'flex'} justifyContent={'center'} sx={{ padding: '0px 4%' }}>
              <Grid container spacing={2} display={'flex'} justifyContent={'space-between'}>
                <Grid size={4}>
                  <Box textAlign={'left'}>
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{ fontSize: '36px', fontWeight: '700', color: '#2B663C' }}
                    >
                      GAME PROVIDERS
                    </Typography>
                    <Typography
                      variant="h2"
                      component="h2"
                      sx={{ fontSize: '20px', fontWeight: '700', mb: 4, color: '#000000' }}
                    >
                      2025.01 Ver
                    </Typography>
                  </Box>
                </Grid>
                <Grid size={8}>
                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        color: '#FFFFFF',
                        backgroundColor: '#00AA2F',
                        borderRadius: '50px',
                        width: '120.39px',
                        height: '45px',
                        marginRight: '30px',
                      }}
                    >
                      All
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        color: '#000000',
                        borderColor: '#00AA2F',
                        borderRadius: '50px',
                        width: '120.39px',
                        height: '45px',
                        marginRight: '30px',
                      }}
                    >
                      Casino
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        color: '#000000',
                        borderColor: '#00AA2F',
                        borderRadius: '50px',
                        width: '120.39px',
                        height: '45px',
                        marginRight: '30px',
                      }}
                    >
                      Slots
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        color: '#000000',
                        borderColor: '#00AA2F',
                        borderRadius: '50px',
                        width: '120.39px',
                        height: '45px',
                        marginRight: '30px',
                      }}
                    >
                      Sports
                    </Button>
                    <TextField
                      id="outlined-basic"
                      label="Search..."
                      variant="outlined"
                      sx={{
                        border: '1px solid #D7D7D7',
                        borderRadius: '4px',
                        borderColor: '#D7D7D7',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              mb={10}
              display={'flex'}
              justifyContent={'center'}
              sx={{ maxWidth: '100%', maxHeight: '100%', color: '#000000' }}
            >
              <Grid container spacing={2}>
                {providers.map((item, index) => (
                  <Grid item xs={6} sm={3} md={1.5} key={index}>
                    <Box sx={{ maxWidth: '100%', maxHeight: '100%', mt: 5 }} display={'flex'} key={item.id}>
                      <Box
                        sx={{
                          backgroundColor: '#ffffff',
                          textAlign: 'centeer',
                          paddingLeft: 5,
                          paddingRight: 5,
                          // width: '628px',
                          // height: '321.56px',
                          maxWidth: '628px',
                          maxHeight: '100%',
                        }}
                      >
                        <Box>{item.image}</Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box>
              <Button
                variant="outlined"
                sx={{
                  color: '#000000',
                  borderColor: '#00AA2F',
                  borderRadius: '50px',
                  width: '120.39px',
                  height: '45px',
                  marginRight: '30px',
                }}
              >
                더 보기
              </Button>
            </Box>
          </Box>
        </div>

        {/* <Container maxWidth="lg">
          <motion.div
            className="card"
            initial={{
              opacity: 0,
              y: 290,
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 2, // Animation duration
              },
            }}
            // viewport={{ once: true }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box className="bannerText" mt={2} mx={8}>
                {bannerText}
              </Box>
              <Box mt={5}>
                <Button hasEndIcon IconEnd={ArrowForwardIosIcon} text="Get Started" color={COLOR.DEFAULT} />
              </Box>
            </Box>
          </motion.div>
        </Container> */}
      </Box>
    </StyledIGamingSolution>
  );
};

export default IGamingSolution;
