import React from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import StyledPremiumService from './styles';

// import { S3 } from '../../../config/api';

// const bannerText =
// 'Reinventing the platform and developing one from scratch — the Jade Soft team is ready to take on your challenges and create an outstanding tailored iGaming solution';

const PremiumService = () => {
  const theme = useTheme();

  return (
    <StyledPremiumService>
      <Box className="homeBg">
        <Box
          sx={{
            textAlign: { xs: 'center', md: 'center' },
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            zIndex: 1,
            padding: '8% 30%',
          }}
        >
          <Typography variant="h2" component="h2" sx={{ fontSize: '48px', fontWeight: '800' }}>
            Premium Service
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{ lineHeight: 1.8, wordWrap: 'break-word', fontWeight: 800, fontSize: '20px', mt: 2 }}
            >
              제이드 소프트의 유명한 관리 서비스는 고도로 숙련된 전문가 팀의 도움을 받아  게이밍 비즈니스의 모든 요구
              사항을 충족합니다. 귀하는 플레이에 확보에 중점을 두고 위험관리, 플레이에 관리, 카지노 로비, 고객지원, CRM,
              KYC ,VIP 등을 포함한 모든 카지노 및 스포츠북 관련 운영을 JADE SOFT 전담 팀이 처리합니다.
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                color: '#000000',
                backgroundColor: '#0F0',
                borderColor: '#00AA2F',
                borderRadius: '4px',
                width: '120.39px',
                height: '45px',
                marginRight: '30px',
                marginTop: 8,
              }}
            >
              문의하기
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledPremiumService>
  );
};

export default PremiumService;
